<template>
  <div>
    <content-template
      :menuName="$t('cfcComment.title')"
      :backButton="true"
      @backToPage="moveToOther('CFC')"
    >
      <template #detail>
        <h4>{{ $t("cfcComment.feedbackDetail") }}</h4>
        <table v-if="!isMobile">
          <tr v-for="(t, i) in fields" :key="`task-${i}`" class="s-flex s-mt-0">
            <div>
              <td
                :class="`s-input-label ${
                  isMobile ? 's-wd-100-min' : 's-wd-200-min'
                }`"
              >
                {{ $t(t.name) }}
              </td>
              <td>:</td>
              <td v-if="t.variable === 'created_at'">
                {{ convertDate(edited_item[t.variable]) }}
              </td>
              <td v-else-if="t.variable === 'hazard'">
                {{
                  lang == "id"
                    ? edited_item["hazard_idn"]
                    : edited_item["hazard_en"]
                }}
              </td>
              <td v-else-if="t.variable === 'is_danger'">
                {{ edited_item[t.variable] == 1 ? "Yes" : "No" }}
              </td>
              <td v-else>
                {{ edited_item[t.variable] }}
              </td>
            </div>
          </tr>
        </table>

        <div v-else>
          <div v-for="(t, i) in fields" :key="`task-alt-${i}`">
            <div class="i-table-cell-alt">
              <span class="i-input-label"
                ><b>{{ $t(t.name) }}</b></span
              >
              <br />
              <span v-if="t.variable !== 'created_at'" class="s-text-black">
                {{ edited_item[t.variable] }}
              </span>
              <span v-else class="s-text-black">
                {{ convertDate(edited_item[t.variable]) }}
              </span>
            </div>
          </div>
        </div>

        <br />
        <div>
          <h4>{{ $t("cfcComment.replies") }}</h4>
          <div class="s-input-fieldset">
            <fieldset>
              <div class="s-ht-300-max" style="overflow-y: scroll">
                <table>
                  <tr
                    v-for="(r, i) in temp_reply"
                    :key="`reply-${i}`"
                    class="s-flex s-mt-0"
                  >
                    <div
                      style="
                        display: flex;
                        width: 100%;
                        justify-content: space-between;
                        align-items: center;
                      "
                    >
                      <div>
                        <td
                          :class="`s-input-label ${
                            isMobile ? 's-wd-100-min' : 's-wd-200-min'
                          }`"
                        >
                          {{ r.created_by }}
                        </td>
                        <td>:</td>
                        <td>
                          {{ r.reply }}
                        </td>
                      </div>
                      <div
                        class="s-fullcenter-flex"
                        v-if="role_type == 'superadmin' || role_type == 'admin'"
                      >
                        <td class="s-flex">
                          <img
                            @click.prevent="confirmationDeleteComment(r)"
                            class="s-cursor-pointer"
                            src="@/assets/icons/trash.svg"
                          />
                        </td>
                      </div>
                    </div>
                  </tr>
                  <tr colspan="3" v-if="temp_reply.length <= 0">
                    <td style="text-align: center">Empty</td>
                  </tr>
                </table>
              </div>
            </fieldset>
          </div>
        </div>
        <div v-if="role_type == 'stakeholder' || role_type == 'expert'">
          <Textarea
            label="Reply"
            v-model="new_reply"
            class="s-mtb-20"
            :error="validation_new_reply"
          />
          <Button class="s-flex s-ml-auto" @click="onSendMessage()"
            >Send</Button
          >
        </div>
      </template>
    </content-template>
    <Modal v-if="dialogDelete" :title="$t('general.warning')">
      <template #modalContent>
        <p>{{ $t("cfcComment.modalDeleteComment") }}</p>

        <div class="s-mtb-20 s-flex">
          <div class="s-ml-auto">
            <Button @click.prevent="onDeleteComment">
              {{ $t("general.yes") }}
            </Button>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { convertDate } from "@/common/timeformat";
import ContentTemplate from "./contentTemplate.vue";
import form from "@/mixins/form";

export default {
  components: { ContentTemplate },
  props: ["inputType"],
  mixins: [form],
  data() {
    return {
      current_id: "",
      edited_item: {
        location: "",
        hazard: "",
        is_danger: "",
        comment: "",
        created_at: "",
      },
      new_reply: "",
      validation_new_reply: "",
      temp_reply: [],

      fields: [
        { name: "cfcComment.location", variable: "location" },
        { name: "cfcComment.hazard", variable: "hazard" },
        { name: "cfcComment.dangerous", variable: "is_danger" },
        { name: "cfcComment.comment", variable: "comment" },
        { name: "cfcComment.dateCreated", variable: "created_at" },
      ],
    };
  },
  created() {
    this.initData();
  },
  computed: {
    dialogDelete() {
      return this.$store.getters["getModal"];
    },
    isMobile() {
      return this.$store.getters["getIsMobile"];
    },
    ...mapGetters({
      role_type: "auth/getRole",
      item: "cfc/getFeedback",
      reply: "cfc/getReply",
      user: "auth/getUser",
    }),
  },

  methods: {
    ...mapActions({
      setFeedbackSingleData: "cfc/setFeedbackSingleData",
      setFeedbackReply: "cfc/setFeedbackReply",
      postFeedbackReply: "cfc/postFeedbackReply",
      setModal: "setModal",
      onDeleteReply: "cfc/onDeleteReply",
    }),

    async initData() {
      this.setLoadingPage(true);
      await this.setFeedbackSingleData(this.$route.params.id);
      await this.setFeedbackReply(this.$route.params.id);
      this.edited_item = Object.assign({}, this.item);
      this.edited_item.hazard = this.item.hazard_en;
      this.temp_reply = [...this.reply];
      this.setLoadingPage(false);
    },

    async onSendMessage() {
      this.validation_new_reply = this.new_reply == "" ? true : false;

      if (!this.validation_new_reply) {
        const data = {
          crowdsourcing_feedback_id: this.$route.params.id,
          reply: this.new_reply,
        };

        await this.postFeedbackReply(data);

        if (this.$store.getters["cfc/getStatus"] == 1) {
          var temp_obj = {};

          temp_obj.created_by = this.user.name;
          temp_obj.reply = this.new_reply;

          this.temp_reply.push(temp_obj);

          //Reset
          this.new_reply = "";
        }
      }
    },

    confirmationDeleteComment(data) {
      this.setModal(data);
      this.current_id = data.id;
    },

    async onDeleteComment() {
      await this.onDeleteReply(this.current_id);
      await this.setFeedbackReply(this.$route.params.id);

      if (this.$store.getters["cfc/getStatus"] == 1) {
        this.temp_reply = [...this.reply];
        this.showSnackbar({
          type: "success",
          text: "Comment successfully deleted",
        });
      }
      this.setModal(null);
    },

    moveToFinish() {
      this.$router.push(this.$translate({ name: "APMA Finish Task" }));
    },

    convertDate(value) {
      return convertDate(value);
    },
  },
};
</script>